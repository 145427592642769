
import './Loader.css'; 

import React from 'react';
import {
  FaBookOpen,
  FaLaptopCode,
  FaLightbulb,
  FaGraduationCap,
  FaPaintBrush,
  FaHeadset,
  FaGuitar,
  FaRocket,
} from 'react-icons/fa';

const Loader = () => {
  const icons = [
    <FaBookOpen style={{ color: '#F7CA4A', fontSize: '50px' }} />, // Orange book
    <FaLaptopCode style={{ color: '#5390D9', fontSize: '45px' }} />, // Blue laptop
    <FaLightbulb style={{ color: '#F1C40F', fontSize: '40px' }} />, // Yellow lightbulb
    <FaGraduationCap style={{ color: '#27AE60', fontSize: '40px' }} />, // Green graduation cap
    <FaPaintBrush style={{ color: '#D4E673', fontSize: '40px' }} />, // Pink paintbrush
    <FaHeadset style={{ color: '#7D3C98', fontSize: '40px' }} />, // Purple headset
    <FaGuitar style={{ color: '#E67E22', fontSize: '40px' }} />, // Orange guitar
    <FaRocket style={{ color: '#29ABCA', fontSize: '40px' }} />, // Blue rocket
  ];
  const [currentIconIndex, setCurrentIconIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 1500); // Change icon every 1.5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-[#101010] loader-container">
      <div className="loader-icon-wrapper">
        <div className="loader-icon" style={{ animationName: 'flip' }}>
          {icons[currentIconIndex]}
        </div>
      </div>
      <div className="loader-text text-white">Loading...</div>
    </div>


  );
};

export default Loader;



