// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
// import Landing from './Components/Pages/Landing';
// import Faq from './Components/Pages/Faq';
// import NotFound from './Components/Pages/NotFound';
// import DnaHome from './Components/Pages/DNA/DnaHome';
// import DnaTracking from './Components/Pages/DNA/DnaTracking';
// import CeoHome from './Components/Pages/Coe/Pages/Home';
// import CeoDetails from './Components/Pages/Coe/Pages/Coe';
// import CeoCareerHub from './Components/Pages/Coe/Pages/CareerHub';
// import CeoMyDashboard from './Components/Pages/Coe/Pages/MyDashboard';
// import CeoDnaAi from './Components/Pages/Coe/Pages/DnaAi';
// import Bingo from './Components/Pages/Game/Bingo';
// import BingoAdmin from './Components/Pages/Game/BingoAdmin';
// import Bingogame from './Components/Pages/Game/Game';
// import ScheduleMeeting from './Components/Pages/Coe/ADMIN/ScheduleMeeting';
// import CreateMeeting from './Components/Pages/Coe/ADMIN/Create_meeitng';
// import AddProjects from './Components/Pages/Coe/ADMIN/AddProjects';
// import TestDemo from './Components/Pages/TestDemo';
// import EditProject from './Components/Pages/Coe/ADMIN/EditProject';

// import EventHome from './Components/Pages/JordanEvent/Pages/Home';
// import AddTeamMembers from './Components/Pages/JordanEvent/Pages/AddTeamMembers';
// import Instruction from './Components/Pages/JordanEvent/Pages/Instruction';
// import TeamMembers from './Components/Pages/JordanEvent/Pages/TeamMembers';
// import ProtectedRoute from './Components/Pages/ProtectedRoute';
// import Assessment from './Components/Pages/JordanEvent/Pages/Assessment';
// import AssessmentResult from './Components/Pages/JordanEvent/Pages/AssessmentResult/Result'

// import AdminJordan from './Components/Pages/JordanEvent/JordanAdmin/Admin';

// import AdminTeamMembers from './Components/Pages/JordanEvent/JordanAdmin/Jordanusers';
// import AdminTeams from './Components/Pages/JordanEvent/JordanAdmin/JordanTeams';
// import SyncEmailPage from './Components/Pages/SyncEmail';
// import Courses from './Components/Pages/AICourses/Courses';
// import CourseDetails from './Components/Pages/AICourses/CourseDetails/CourseDetails';
// import CoursePreview from './Components/Pages/AICourses/CoursePreview/CoursePreview';
// import Terms_Condition from './Components/T&C/Terms_Condition';
// import Privacy_Policy from './Components/T&C/Privacy_Policy';
// import Refund_Policy from './Components/T&C/Refund_Policy';
// import ScrollToTop from './Components/Pages/ScrollToTop';


// function Routers() {

//   const subdomain = window.location.host.split('-')[0];
//   const isGame = subdomain === 'game';

//   console.log("isGame", isGame)

//   return (

//     <div>
//       {/* {!isGame ? ( */}
      
//       <ScrollToTop />
//         <Routes>
//           <Route path="/" element={<Landing />} />
//           <Route path="/Faq" element={<Faq />} />
//           <Route path="*" element={<NotFound />} />
//           <Route path="/sync-email" element={<SyncEmailPage />} />
//           <Route path="/ai-courses" element={<Courses />} />
//           <Route path="/course-details/:id" element={<CourseDetails />} />
//           <Route path="/course-preview" element={<CoursePreview />} />

//           <Route path="/terms-and-conditions" element={<Terms_Condition />} />
//           <Route path="/privacy-policy" element={<Privacy_Policy />} />
//           <Route path="/refund-policy" element={<Refund_Policy />} />

//           {/*DNA*/}
//           <Route path="/dna-home" element={<DnaHome />} />
//           <Route path="/dna-track" element={<DnaTracking />} />
//           {/*COE Portal*/}
//           <Route path="/coe/home" element={<CeoHome />} />
//           <Route path="/coe/details" element={<CeoDetails />} />
//           <Route path="/coe/mydashboard" element={<CeoMyDashboard />} />
//           <Route path="/coe/careerhub" element={<CeoCareerHub />} />
//           <Route path="/coe/dna_ai" element={<CeoDnaAi />} />
//           <Route path="/coe/admin/schedul-meeting" element={<ScheduleMeeting />} />
//           <Route path="/coe/admin/create-meeting" element={<CreateMeeting />} />
//           <Route path="/coe/admin/add-projects" element={<AddProjects />} />
//           <Route path="/coe/admin/edit-projects" element={<EditProject />} />
//           {/*Events*/}
//           {/* <Route path="/jordan-event/" element={<EventHome />} />
//           <Route path="/jordan-event/add-teammembers" element={<AddTeamMembers />} />
//           <Route path="/jordan-event/instruction" element={<Instruction />} />
//           <Route path="/jordan-event/start-assessment" element={<Assessment />} />
//           <Route path="/jordan-event/team-members" element={<TeamMembers />} />
//  */}

//           <Route
//             path="/jordan-event/"
//             element={<ProtectedRoute element={<EventHome />} />}
//           />
//           <Route
//             path="/jordan-event/add-teammembers"
//             element={<ProtectedRoute element={<AddTeamMembers />} />}
//           />
//           <Route
//             path="/jordan-event/instruction"
//             element={<ProtectedRoute element={<Instruction />} />}
//           />
//           <Route
//             path="/jordan-event/start-assessment"
//             element={<ProtectedRoute element={<Assessment />} />}
//           />
//           <Route
//             path="/jordan-event/assessment-result"
//             element={<ProtectedRoute element={<AssessmentResult />} />}
//           />
//           <Route
//             path="/jordan-event/team-members"
//             element={<ProtectedRoute element={<TeamMembers />} />}
//           />



//           <Route path="/test" element={<TestDemo />} />

//           {/*   ====================ADMIN========== */}

//           <Route path="/admin/jordan" element={<AdminJordan />} />
//           <Route path="/admin/jordan-users" element={<AdminTeamMembers />} />
//           <Route path="/admin/jordan-teams" element={<AdminTeams />} />




//         </Routes>

//     {/*
//       ) : (
//         <Routes>
//           <Route path="/" element={<Bingogame />} />
//           <Route path="/game-track" element={<BingoAdmin />} />
//           <Route path="*" element={<NotFound />} />

//         </Routes>
//       )}
//         */}
//     </div>


//   );
// }

// export default Routers;




import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Components/Pages/ScrollToTop';
import Loader from './Components/Loaders/Loader';

// Lazy load components
const Landing = lazy(() => import('./Components/Pages/Landing'));
const Faq = lazy(() => import('./Components/Pages/Faq'));
const NotFound = lazy(() => import('./Components/Pages/NotFound'));
const DnaHome = lazy(() => import('./Components/Pages/DNA/DnaHome'));
const DnaTracking = lazy(() => import('./Components/Pages/DNA/DnaTracking'));
const CeoHome = lazy(() => import('./Components/Pages/Coe/Pages/Home'));
const CeoDetails = lazy(() => import('./Components/Pages/Coe/Pages/Coe'));
const CeoCareerHub = lazy(() => import('./Components/Pages/Coe/Pages/CareerHub'));
const CeoMyDashboard = lazy(() => import('./Components/Pages/Coe/Pages/MyDashboard'));
const CeoDnaAi = lazy(() => import('./Components/Pages/Coe/Pages/DnaAi'));
const Bingo = lazy(() => import('./Components/Pages/Game/Bingo'));
const BingoAdmin = lazy(() => import('./Components/Pages/Game/BingoAdmin'));
const Bingogame = lazy(() => import('./Components/Pages/Game/Game'));
const ScheduleMeeting = lazy(() => import('./Components/Pages/Coe/ADMIN/ScheduleMeeting'));
const CreateMeeting = lazy(() => import('./Components/Pages/Coe/ADMIN/Create_meeitng'));
const AddProjects = lazy(() => import('./Components/Pages/Coe/ADMIN/AddProjects'));
const TestDemo = lazy(() => import('./Components/Pages/TestDemo'));
const EditProject = lazy(() => import('./Components/Pages/Coe/ADMIN/EditProject'));
const EventHome = lazy(() => import('./Components/Pages/JordanEvent/Pages/Home'));
const AddTeamMembers = lazy(() => import('./Components/Pages/JordanEvent/Pages/AddTeamMembers'));
const Instruction = lazy(() => import('./Components/Pages/JordanEvent/Pages/Instruction'));
const TeamMembers = lazy(() => import('./Components/Pages/JordanEvent/Pages/TeamMembers'));
const ProtectedRoute = lazy(() => import('./Components/Pages/ProtectedRoute'));
const Assessment = lazy(() => import('./Components/Pages/JordanEvent/Pages/Assessment'));
const AssessmentResult = lazy(() => import('./Components/Pages/JordanEvent/Pages/AssessmentResult/Result'));
const AdminJordan = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/Admin'));
const AdminTeamMembers = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/Jordanusers'));
const AdminTeams = lazy(() => import('./Components/Pages/JordanEvent/JordanAdmin/JordanTeams'));
const SyncEmailPage = lazy(() => import('./Components/Pages/SyncEmail'));
const Courses = lazy(() => import('./Components/Pages/AICourses/Courses'));
const CourseDetails = lazy(() => import('./Components/Pages/AICourses/CourseDetails/CourseDetails'));
const CoursePreview = lazy(() => import('./Components/Pages/AICourses/CoursePreview/CoursePreview'));
const Terms_Condition = lazy(() => import('./Components/T&C/Terms_Condition'));
const Privacy_Policy = lazy(() => import('./Components/T&C/Privacy_Policy'));
const Refund_Policy = lazy(() => import('./Components/T&C/Refund_Policy'));

function Routers() {
  const subdomain = window.location.host.split('-')[0];
  const isGame = subdomain === 'game';
  
  console.log("isGame", isGame)

  return (
    <div>
      <ScrollToTop />
      <Suspense fallback={<div><Loader/></div>}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/sync-email" element={<SyncEmailPage />} />
          <Route path="/ai-courses" element={<Courses />} />
          <Route path="/course-details/:id" element={<CourseDetails />} />
          <Route path="/course-preview" element={<CoursePreview />} />
          <Route path="/terms-and-conditions" element={<Terms_Condition />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/refund-policy" element={<Refund_Policy />} />
          <Route path="/dna-home" element={<DnaHome />} />
          <Route path="/dna-track" element={<DnaTracking />} />
          <Route path="/coe/home" element={<CeoHome />} />
          <Route path="/coe/details" element={<CeoDetails />} />
          <Route path="/coe/mydashboard" element={<CeoMyDashboard />} />
          <Route path="/coe/careerhub" element={<CeoCareerHub />} />
          <Route path="/coe/dna_ai" element={<CeoDnaAi />} />
          <Route path="/coe/admin/schedul-meeting" element={<ScheduleMeeting />} />
          <Route path="/coe/admin/create-meeting" element={<CreateMeeting />} />
          <Route path="/coe/admin/add-projects" element={<AddProjects />} />
          <Route path="/coe/admin/edit-projects" element={<EditProject />} />
          <Route path="/test" element={<TestDemo />} />
          <Route path="/admin/jordan" element={<AdminJordan />} />
          <Route path="/admin/jordan-users" element={<AdminTeamMembers />} />
          <Route path="/admin/jordan-teams" element={<AdminTeams />} />
          <Route path="/jordan-event/" element={<ProtectedRoute element={<EventHome />} />} />
          <Route path="/jordan-event/add-teammembers" element={<ProtectedRoute element={<AddTeamMembers />} />} />
          <Route path="/jordan-event/instruction" element={<ProtectedRoute element={<Instruction />} />} />
          <Route path="/jordan-event/start-assessment" element={<ProtectedRoute element={<Assessment />} />} />
          <Route path="/jordan-event/assessment-result" element={<ProtectedRoute element={<AssessmentResult />} />} />
          <Route path="/jordan-event/team-members" element={<ProtectedRoute element={<TeamMembers />} />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default Routers;
